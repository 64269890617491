import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/Loader';
import { AuthErrorModal } from 'components/Auth';

export default function AuthCallback() {
  const { error, logout } = useAuth0();

  useEffect(() => {
    if (error) {
      logout({ localOnly: true });
    }
  }, [error, logout]);

  return error ? (
    <AuthErrorModal
      isOpen={true}
      className=''
      closeOnOutsideClick={false}
      message={error.message}
    />
  ) : (
    <Loader isOpen={true} />
  );
}
